import classes from './ObjednatSa.module.css'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import {BsFillTelephoneFill} from 'react-icons/bs';
import {HiMail} from 'react-icons/hi';
import ZmluvniPartneri from "./../ZmluvniPartneri/ZmluvniPartneri";

const ObjednatSa = () => {
    return (
        <div>
        <div className = {classes.ObjednatSa} id="objednatSa">
            <div className={classes.objednatSa}>  
                <div className={classes.objednatSaNadpisDiv}>
                    <h2>Objednajte sa</h2>
                </div> 
                <div className={classes.hlavnyPasik}>
                    <div id={classes.telefonickySposob} className={classes.sposob}>
                        <div className={classes.telefonickySub}>
                            <h3>Telefonicky alebo cez SMS</h3>
                            <div className={classes.druhyRiadok}>
                                <BsFillTelephoneFill size='2.5rem' color='black' 
                                    style={{
                                        height:'1.8rem',
                                        width: 'auto',
                                        color: '#203C57',
                                        paddingRight: '0.625rem',
                                    }}
                                />
                                <h2>0949 446 767</h2>
                            </div>
                        </div>
                    </div> 
                    <div className={classes.vertikalnaCiara}> 
                    </div>
                    <div className={classes.sposob}>
                        <div className={classes.cezInternet}>
                            <h3>Cez internet</h3>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.objednatvysetrenie.sk/vyber-druhu-vysetrenia.html?page_id=89960&zid=143125">
                                <div className={classes.druhyRiadok}>
                                <BsFillCalendarCheckFill size='2.5rem' color='black' 
                                        style={{
                                            height:'auto',
                                            width: '2.5rem',
                                            color: '#203C57',
                                            paddingRight: '0.625rem',
                                        }}
                                    />
                                <div>
                                        <h2>Objednať sa</h2>
                                        <h4>kliknutím sem</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>                    
                    <div className={classes.vertikalnaCiara}> 
                    </div>  
                    <div className={classes.sposob}>
                        <div className={classes.mailomSub}>
                            <h3>E-mailom</h3>
                            <div className={classes.druhyRiadok}>
                                <HiMail size='2.5rem' color='black' 
                                    style={{
                                        height:'2.4rem',
                                        width: 'auto',
                                        color: '#203C57',
                                        paddingRight: '0.625rem',
                                    }}
                                />
                                <h2>ambulancia@pulmonar.sk</h2>
                            </div>
                        </div>
                    </div>                                       
                </div>                        
            </div>
        </div>
        <ZmluvniPartneri />
        </div> 
    );
}
 
export default ObjednatSa;