import classes from './Sluzby.module.css'
import {FaCommentMedical} from 'react-icons/fa';
import {RiLungsFill} from 'react-icons/ri';
import {TbStethoscope} from 'react-icons/tb';

const Sluzby = () => {
    return ( 
        <div className = {classes.Sluzby} id="sluzby">
            <div className={classes.poskytujeme}>
                <div className={classes.subPoskytujeme}>
                    <div className={classes.poskytujemeItems}>
                        <div>
                            <TbStethoscope size='2.5rem' color='black' 
                            style={{
                                marginTop : '2.5rem',
                                marginBottom: '0.938rem',
                                height:'4.063rem',
                                width: 'auto',
                                color: '#203C57'
                            }}
                            />
                            <h3>Vyšetrenie</h3>
                        </div>
                        <div>                          
                            <RiLungsFill size='2.5rem' color='black' 
                            style={{
                                marginTop : '2.5rem',
                                marginBottom: '0.938rem',
                                height:'4.063rem',
                                width: 'auto',
                                color: '#203C57'
                            }}
                            />
                            <h3>Diagnostika</h3>
                        </div>
                        <div>
                            <FaCommentMedical size='2.5rem' color='black' 
                            style={{
                                marginTop : '2.5rem',
                                marginBottom: '0.938rem',
                                height:'4.063rem',
                                width: 'auto',
                                color: '#203C57'
                            }}
                            />
                            <h3>Konzultácia</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}
 
export default Sluzby;