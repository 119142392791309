import classes from './NajdeteNas.module.css'
import {FaWalking} from 'react-icons/fa';
import {BsStopwatchFill} from 'react-icons/bs';
import {FaSearchLocation} from 'react-icons/fa';

const NajdeteNas = () => {
    return ( 
        <div className = {classes.NajdeteNas} id="najdeteNas">
            <div className={classes.kdeNasNajdete}>
                <h2>Nájdete nás</h2> 
                <div className = {classes.kdeNasNajdeteItemList}>
                    <div className = {classes.kdeNasNajdeteItem}>
                        <FaWalking size='2.5rem' color='black' 
                                    style={{
                                        height:'auto',
                                        width: '5.2rem',
                                        color: '#203C57',
                                        paddingRight: '0.9375rem',
                                    }}
                                />
                        <h3>5 min. peši<br/> od železničnej a autobusovej stanice</h3>
                    </div>

                    <div className = {classes.kdeNasNajdeteItem}>
                        <BsStopwatchFill size='2.5rem' color='black' 
                                    style={{
                                        height:'auto',
                                        width: '5.2rem',
                                        color: '#203C57',
                                        paddingRight: '0.9375rem',
                                    }}
                                />                                
                        <h3>3 min. peši<br/>od Fakultnej nemocnice Trnava</h3>
                    </div>
                </div>
                    <div className={classes.kdeNasNajdeteMapa}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2650.453061619683!2d17.583865630019897!3d48.37103247646913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476b5fe4088f4d27%3A0xf8b95869ba81a116!2sP%C4%BE%C3%BAcna%20ambulancia%20Pulmonar!5e0!3m2!1ssk!2sch!4v1663017381103!5m2!1ssk!2sch" 
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade"
                            title="map"></iframe>
                    </div>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/dir//P%C4%BE%C3%BAcna+ambulancia+Pulmonar,+Klempova+4,+917+01+Trnava,+Slovensko/@48.3710951,17.5880425,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0x476b5fe4088f4d27:0xf8b95869ba81a116!2m2!1d17.5883503!2d48.371029!3e0">
                    <div className={classes.kdeNasNajdeteAdresa}>
                        <FaSearchLocation size='2.5rem' color='black' 
                                        style={{
                                            height:'auto',
                                            width: '3rem',
                                            color: '#203C57',
                                            paddingRight: '0.9375rem',
                                        }}
                        />
                        <h3>Klempova 4<br/> 917 01 Trnava</h3>
                    </div>
                </a>
            </div>
        </div> 
    );
}
 
export default NajdeteNas;