import classes from './Footer.module.css'
import imgHadDole from './../../img/hadDole.png'
const Footer = () => {
    return ( 
        <div className = {classes.Footer}>
            <footer>
                <img className={classes.footerLogo} src={imgHadDole} alt="logo" />
                <p>© Pulmonar s.r.o.</p>
                <p></p>
            </footer>  
        </div> 
    );
}
 
export default Footer;