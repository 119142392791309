import classes from './OrdHodPersonal.module.css'
import OrdinacneHodinyCard from '../cards/OrdinacneHodinyCard/OrdinacneHodinyCard';
import PersonalCard from '../cards/PersonalCard/PersonalCard';
const OrdHodPersonal = () => {
    return ( 
        <div className = {classes.OrdHodPersonal} id="ordinacneHodiny">
            <div className={classes.ordHodAKontakt}>
                <OrdinacneHodinyCard />
                <PersonalCard />
            </div>            
        </div> 
    );
}
 
export default OrdHodPersonal;