import NavLinks from "./NavLinks";
import classes from './NavBar.module.css';
import {TbMenu2} from 'react-icons/tb';
import {GrClose} from 'react-icons/gr';
import {useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';

const MobileNavigation = () => {

    const animateOpenButtonFrom = {opacity: 0, x: 200} 
    const animateOpenButtonTo = {opacity: 1, x: 0};

    const animateCloseButtonFrom = {opacity: 0, y: -200};
    const animateCloseButtonTo = {opacity: 1, y: 0};

    const [open, setOpen] = useState(false);

    const hamburgerIcon =   <motion.div className={classes.helpingDiv}
                                key="openMmenuButton"
                                initial={animateOpenButtonFrom}
                                animate={animateOpenButtonTo}
                                exit={{
                                    x:200,
                                    opacity: 0
                                }}
                                transition={{duration:0.3}}
                                >
                            <TbMenu2 className={classes.Hamburger} 
                                size='2.5rem' color='black' 
                                onClick={() => setOpen(!open)}
                            />
                            </motion.div>

    const closeMobileMenu = () => setOpen(false);
                           
    const closeIcon = <motion.div className={classes.helpingDiv}
                            key="closeMenuButton"
                            initial={animateCloseButtonFrom}
                            animate={animateCloseButtonTo}
                            exit={{
                                y:-200,
                                opacity:0,
                            }}
                            transition={{duration:0.3}}
                        >
                        <GrClose 
                            className={classes.Hamburger} 
                            size='2.5rem' color='black' 
                            onClick={() => setOpen(!open)}
                        />
                        </motion.div>
    return (
        <nav  className={classes.MobileNavigation}>
            <AnimatePresence>
                {open ? closeIcon : hamburgerIcon}
            
            {open && <>
                <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu}/>
                <div onClick={() => closeMobileMenu()} 
                    closeMobileMenu={closeMobileMenu} 
                    className={classes.OutsideZone}>
                </div>
            </>}
            </AnimatePresence>
        </nav>
    );
}
 
export default MobileNavigation;