import classes from './Ambulancia.module.css'

const Ambulancia = () => {
    return ( 
        <div className = {classes.Ambulancia}>
            <section className= {classes.parallax} fetchpriority="low">                
            </section>
        </div> 

    );
}
 
export default Ambulancia;