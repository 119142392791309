import classes from './Landing.module.css'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { Link } from "react-scroll";
const Landing = () => {
    return ( 
        <div className={classes.Landing}>
            <div className={classes.backgroundImg}></div>
            <div className={classes.modraVrstva}></div>  
            <div className ={classes.prednaVrstva}>
                <div className={classes.subPrednaVrstva}>
                    <h1>Pľúcna ambulancia</h1>
                    <div className={classes.landingStrednyRiadok}>
                        <div className={classes.lavaCiara}></div>
                        <h3>Pulmonar s.r.o.</h3>
                        <div className={classes.pravaCiara}></div>
                    </div>       
                    <Link className = {classes.objednatSaDiv} to="objednatSa" spy={true} smooth={true} offset={-55} duration={500}>         
                        <h2 className={classes.objednatSaText}>Objednajte sa: </h2>
                        <div>
                            <BsFillTelephoneFill size='2.5rem' color='black' 
                                style={{
                                    height:'2.2rem',
                                    width: 'auto',
                                    color: '#203C57',
                                }}
                            />
                            <h2 className={classes.telCislo} >0949 446 767</h2>
                        </div>                
                    </Link>
                </div>
            </div>            
        </div> 
    );
}
 
export default Landing;