import classes from './ScrollToTop.module.css'
import { useWindowScroll } from 'react-use';
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import {IoIosArrowUp} from 'react-icons/io';
import {IoIosArrowDown} from 'react-icons/io';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

const ScrollToTop = () => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { y: pageYOffset } = useWindowScroll();
    const [visibleScrollToTop, setVisibilityScrollToTop] = useState(false);
    const [visibleScrollDown, setVisibilityScrollDown] = useState(true);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        if(pageYOffset < windowDimensions.height*0.9){
            setVisibilityScrollDown(true);
            setVisibilityScrollToTop(false);
        }        
        else if(pageYOffset/(document.documentElement.scrollHeight-windowDimensions.height)> 0.3){
            setVisibilityScrollDown(false);
            setVisibilityScrollToTop(true);
        }
        else{
            setVisibilityScrollDown(false);
            setVisibilityScrollToTop(false);
        }
    }, [pageYOffset, windowDimensions.height])

    if(visibleScrollToTop){
        return(
            <Link className= {classes.ScrollToTop} to="uvod" spy={true} smooth={true} offset={-60} duration={500}>
                <IoIosArrowUp size='2.5rem' color='black' 
                    style={{
                        height:'auto',
                        width: '1.875rem',
                        color: 'white',
                        transition: '0.2s'                        
                    }}
                />
            </Link>
        );
    } else if(visibleScrollDown){
        return(
            <Link className={classes.sipkaDole} to="sluzby" spy={true} smooth={true} offset={-55} duration={500}>
                <IoIosArrowDown className={classes.sipkaDoleImg} size='2.5rem' color='black' 
                    style={{
                        height:'auto',
                        width: '4.625rem',
                        color: 'white',
                        transition: '0.5s'                       
                    }}
                    />
            </Link>            
        );
    } else {
        return false;      
    }
}
 
export default ScrollToTop;