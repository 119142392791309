import classes from './NavBar.module.css'
import { Link } from "react-scroll";
import {motion} from 'framer-motion'

const NavLinks = (props) => {

    const animateItemFrom = {opacity: 0, y: -40}
    const animateItemTo = {opacity: 1, y: 0}

    const animateDivFrom = {opacity: 1, y: -200}
    const animateDivTo = {opacity: 1, y: 0}

    return ( 
        <motion.ul
        initial={animateDivFrom}
        animate={animateDivTo}
        transition={{delay: 0, restSpeed: 0.5}}
        exit={{
            y:-300,
            opacity:0,
            transition:{duration:0.6}
        }}
        >
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo}
                transition={{delay: 0.05}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="sluzby" spy={true} smooth={true} offset={-55} duration={500}>Služby</Link>
            </motion.li>
            <motion.li
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.10}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="ordinacneHodiny" spy={true} smooth={true} offset={-55} duration={500}>Ordinačné hodiny</Link>
            </motion.li>
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.20}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="objednatSa" spy={true} smooth={true} offset={-55} duration={500}>Objednať sa</Link>
            </motion.li>
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.30}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="najdeteNas" spy={true} smooth={true} offset={-55} duration={500}>Kde nás nájdete</Link>
            </motion.li>
        </motion.ul>
    );
}
 
export default NavLinks;