import NavBar from "./components/NavBar/NavBar";
import Landing from "./components/Landing/Landing";
import Sluzby from "./components/Sluzby/Sluzby";
import OrdHodPersonal from "./components/OrdHodPersonal/OrdHodPersonal";
import NajdeteNas from "./components/NajdeteNas/NajdeteNas";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"
import ObjednatSa from "./components/ObjednatSa/ObjednatSa";
import Ambulancia from "./components/Ambulancia/Ambulancia";
const App = () => {
  return (
    <div className="App">
      <NavBar />
      <Landing />
      <Sluzby />
      <OrdHodPersonal /> 
      <ObjednatSa />     
      <NajdeteNas />
      <Ambulancia />
      <Footer /> 
      <ScrollToTop />
    </div>
  );
}

export default App;
