import classes from './OrdinacneHodinyCard.module.css'
const OrdinacneHodinyCard = () => {
    return ( 
        <div className = {classes.OrdinacneHodinyCard}>                  
            <div className={classes.ordHodAKontaktContentItem}>
                <div className={classes.ordHodNadpisDiv}>
                    <h2>Ordinačné hodiny</h2>
                </div>
                <div className={classes.ordHodAKontaktCiara}></div>
                <div className={classes.ordHodObsah}>
                    <div className={classes.ordHodDni}>
                        <p>Pondelok:</p>
                        <p>Utorok:</p>
                        <p>Streda:</p>
                        <p>Štvrtok:</p>
                        <p>Piatok:</p>
                    </div>
                    <div className={classes.ordHodCasy}>
                        <p>06,30 - 14,00 <span>hod.</span></p>
                        <p>07,00 - 13,30 <span>hod.</span></p>
                        <p>06,30 - 13,30 <span>hod.</span></p>
                        <p>07,00 - 14,00 <span>hod.</span></p>
                        <p>06,30 - 13,00 <span>hod.</span></p>
                    </div> 
                </div>
            </div>                       
        </div> 
    );
}
 
export default OrdinacneHodinyCard;