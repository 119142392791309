import classes from './NavBar.module.css'
import imgLogo from './../../img/logo.svg'
import { Link } from "react-scroll";
import MobileNavigation from './MobileNavigation';
import Navigation from './Navigation';
const NavBar = () => {
    return ( 
        <div className = {classes.NavBar} id="uvod">
            <header>
            <div className={classes.ribbon}></div>
                <div>
                    <Link className= {classes.logoDiv} to="uvod" spy={true} smooth={true} offset={-60} duration={500}>
                            <img src={imgLogo} alt="logo" />
                            <p>ulmonar s.r.o.</p>
                    </Link>
                    <MobileNavigation />
                    <Navigation />
                </div>
            </header>
        </div> 
    );
}
 
export default NavBar;