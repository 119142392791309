import classes from './PersonalCard.module.css'
import {FaUserMd} from 'react-icons/fa';

const PersonalCard = () => {
    return ( 
        <div className = {classes.PersonalCard}>             
            <div className={classes.personalContentItem}>                    
                <div className={classes.personalNadpisDiv}>
                    <h2>Personál</h2>
                </div>
                <div className={classes.personalCiara}></div>
                <div className={classes.personalObsah}>
                    <div className={classes.personalObsahElement}>
                        <FaUserMd size='2.5rem' color='black' 
                            style={{
                                marginRight : '3%',
                                marginLeft: '3%',
                                height:'auto',
                                width: '3.125rem',
                                color: '#203C57'
                            }}
                        />
                        <div>
                            <h3>MUDr. Diana Lím Bartovicová, MPH</h3>
                            <h4>odborný lekár - pneumológ a ftizeológ</h4>
                        </div>
                    </div>  
                    <div className={classes.personalObsahElement}>
                        <FaUserMd size='2.5rem' color='black' 
                            style={{
                                marginRight : '3%',
                                marginLeft: '3%',
                                height:'auto',
                                width: '3.125rem',
                                color: '#203C57'
                            }}
                        />
                        <div>
                            <h3>Mgr. Veronika Benková</h3>
                            <h4>sestra</h4>
                        </div>                          
                    </div>  
                </div>
            </div>                
        </div>
    );
}
 
export default PersonalCard;