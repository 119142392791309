import classes from './ZmluvniPartneri.module.css'
import imgVszpLogo from './../../img/vszp.png'
import imgUnionLogo from './../../img/uzp.png'
import imgDoveraLogo from './../../img/dovera.png'

const ZmluvniPartneri = () => {
    return ( 
        <div className = {classes.ZmluvniPartneri}>
            <div className={classes.zmluvnePoistovne}>
                <h2>Zmluvní partneri</h2>
                <div className={classes.zmluvnePoistovneContent}>
                    <div>
                        <img className={classes.vszpLogo} src={imgVszpLogo} alt="logo vszp" />
                    </div>
                    <div>
                        <img className={classes.unionLogo} src={imgUnionLogo} alt="logo union" />
                    </div>  
                    <div>
                        <img className={classes.doveraLogo} src={imgDoveraLogo} alt="logo dovera" />
                    </div>                                      
                </div>
            </div>
        </div>
    );
}
 
export default ZmluvniPartneri;